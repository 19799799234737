const list = [
    {"branchID":"1","branchName":"Adeniran Ogunsanya College of Education (AOCOED)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:06:17"},
    {"branchID":"2","branchName":"Ansarudeen College of Education (ADCOED) Isolo","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:02:43"},
    {"branchID":"3","branchName":"Federal College of Education Akoka FCE(T)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:02:43"},
    {"branchID":"4","branchName":"Lagos State Polytechnic (Laspotech IKORODU)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:05:44"},
    {"branchID":"5","branchName":"Lagos State Polytechnic (Laspotech ISOLO)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:05:44"},
    {"branchID":"6","branchName":"Lagos State Polytechnic (Laspotech SURULERE)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:05:44"},
    {"branchID":"7","branchName":"Lagos State School of Health Technology (LASCOHET)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:02:43"},
    {"branchID":"8","branchName":"LASU College of Medicine, Ikeja (LASUCOM)","branchPhone":"0","branchMail":"lasucom@mssnlagos.net","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:19:58"},
    {"branchID":"9","branchName":"Lagos State University (LASU) Epe","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:05:44"},
    {"branchID":"10","branchName":"Lagos State University (LASU) Ojo","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2012-05-15 21:05:44"},
    {"branchID":"11","branchName":"College of Medicine, Idi-Araba (CMUL\/LUTH)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:19:00"},
    {"branchID":"12","branchName":"Michael Otedola College of Primary Education, Epe (MOCPED)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:48:59"},
    {"branchID":"13","branchName":"Federal College of Fisheries and Marine Technology (FEDFISH) (OCEANOGRAPHY)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:51:26"},
    {"branchID":"14","branchName":"St. Augustine College of Education [Project Time], Akoka (SACOED)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:54:14"},
    {"branchID":"15","branchName":"University of Lagos, Akoka (UNILAG)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:55:13"},
    {"branchID":"16","branchName":"Yaba College of Technology, Yaba (YABATECH)","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-05-16","modified":"2014-11-30 20:56:12"},
    {"branchID":"17","branchName":"Agbowa-Ikosi","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"Friday","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"18","branchName":"Agege","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"19","branchName":"Alimosho","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"20","branchName":"Ikotun","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"21","branchName":"Badagry","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"22","branchName":"Epe","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"23","branchName":"Alagbado","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"24","branchName":"Ifako","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"25","branchName":"Iju","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"26","branchName":"Iganmu-Coker","branchPhone":"","branchMail":"iganmucoker@mssnlagos.org","branchSec":"Iganmu-Coker","usrahTime":"0000-00-00 00:00:00","usrahVen":"Iganmu-Coker","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 20:07:24"},
    {"branchID":"27","branchName":"Ojodu","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:51:48"},
    {"branchID":"28","branchName":"Ikeja","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"29","branchName":"Igbogbo","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"30","branchName":"Owutu","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:23:33"},
    {"branchID":"31","branchName":"Odogunyan","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"32","branchName":"Egbe","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"33","branchName":"Ejigbo","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"34","branchName":"Estate\/Oke","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"35","branchName":"Ilasa","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 19:05:48"},
    {"branchID":"36","branchName":"Isolo","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"37","branchName":"Alapere","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"38","branchName":"Kosofe-Ajegunle","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"39","branchName":"Maryland","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"40","branchName":"Ogudu-Ojota","branchPhone":"","branchMail":"oguduojota@mssnlagos.org","branchSec":"Ogudu-Ojota","usrahTime":"0000-00-00 00:00:00","usrahVen":"Ogudu-Ojota","usrahDay":"Sunday","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"41","branchName":"Ikosi-Shangisha","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"42","branchName":"Lagos State University Anthony Campus","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2012-12-16","modified":"2024-01-14 02:11:23"},
    {"branchID":"43","branchName":"Iwaya","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:52:54"},
    {"branchID":"44","branchName":"Abule Oja–Abule Ijesha","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:53:49"},
    {"branchID":"45","branchName":"Mushin","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"46","branchName":"Odi-Olowo","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"47","branchName":"Idi-Araba","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:52:54"},
    {"branchID":"48","branchName":"Ajangbadi","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"49","branchName":"Afromedia","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"50","branchName":"Agboju-Amuwo","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 19:59:15"},
    {"branchID":"51","branchName":"Isashi","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"52","branchName":"Iba","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"53","branchName":"Oshodi","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"54","branchName":"Shogunle","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"55","branchName":"Mafoluku","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"56","branchName":"Bariga","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"57","branchName":"Obanikoro","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"58","branchName":"Oworo-Ifako","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"59","branchName":"Surulere","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"60","branchName":"Itire","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2014-11-30 18:29:27"},
    {"branchID":"61","branchName":"Not Listed","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2012-12-16","modified":"2012-12-15 11:54:47"},
    {"branchID":"62","branchName":"Orile-Agege","branchPhone":"","branchMail":"orileagege@mssnlagos.org","branchSec":"Orile-Agege","usrahTime":"2014-12-05 06:14:18","usrahVen":"Orile-Agege","usrahDay":"Saturday","branchType":"Central Branch","dateAdded":"0000-00-00","modified":"2014-11-30 16:14:44"},
    {"branchID":"63","branchName":"Owode Ajegunle","branchPhone":"","branchMail":"owodeajegunle@mssnlagos.org","branchSec":"Owode Ajegunle","usrahTime":"2014-12-05 06:14:18","usrahVen":"Owode Ajegunle","usrahDay":"Sunday","branchType":"Central Branch","dateAdded":"0000-00-00","modified":"2014-11-30 16:19:15"},
    {"branchID":"64","branchName":"Makoko","branchPhone":"","branchMail":"makoko@mssnlagos.org","branchSec":"Makoko","usrahTime":"2014-12-05 06:14:18","usrahVen":"Makoko","usrahDay":"Sunday","branchType":"Central Branch","dateAdded":"0000-00-00","modified":"2014-11-30 18:31:52"},
    {"branchID":"65","branchName":"Ijora-Badiah","branchPhone":"","branchMail":"ijorabadiah@mssnlagos.org","branchSec":"Ijora-Badiah","usrahTime":"2014-12-05 06:14:18","usrahVen":"Ijora-Badiah","usrahDay":"Sunday","branchType":"Central Branch","dateAdded":"0000-00-00","modified":"2014-11-30 20:03:22"},
    {"branchID":"66","branchName":"Iponri","branchPhone":"","branchMail":"iponri@mssnlagos.org","branchSec":"Iponri","usrahTime":"2014-12-05 06:14:18","usrahVen":"Iponri","usrahDay":"Saturday","branchType":"Central Branch","dateAdded":"0000-00-00","modified":"2014-11-30 21:53:48"},
    {"branchID":"67","branchName":"Federal School of Occupational Therapy, Oshodi (FSOT)","branchPhone":"","branchMail":"fsot@mssnlagos.net","branchSec":"","usrahTime":"2014-12-06 00:00:00","usrahVen":"","usrahDay":"Wednesday","branchType":"Higher Institution Branch","dateAdded":"0000-00-00","modified":"2014-11-30 22:00:30"},
    {"branchID":"68","branchName":"Grace Polytechnic, Surulere (Grace)","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"2014-12-05 06:14:18","usrahVen":"Grace Polytechnic, Surulere (Grace)","usrahDay":"Tuesday","branchType":"Higher Institution Branch","dateAdded":"0000-00-00","modified":"2014-11-30 22:02:15"},
    {"branchID":"69","branchName":"Lagos City Polytechnic, Ikeja (Lagos City)","branchPhone":"","branchMail":"","branchSec":"Lagos City Polytechnic, Ikeja (Lagos City)","usrahTime":"2014-12-06 00:00:00","usrahVen":"Lagos City Polytechnic, Ikeja (Lagos City)","usrahDay":"Tuesday","branchType":"Higher Institution Branch","dateAdded":"0000-00-00","modified":"2014-11-30 22:02:15"},
    {"branchID":"70","branchName":"Lagos State University, Surulere (AASOC) (LASUSOC)","branchPhone":"","branchMail":"","branchSec":"Lagos State University, Surulere (AASOC) (LASUSOC)","usrahTime":"2014-12-05 06:14:18","usrahVen":"Lagos State University, Surulere (AASOC) (LASUSOC)","usrahDay":"Tuesday","branchType":"Higher Institution Branch","dateAdded":"0000-00-00","modified":"2014-11-30 22:03:19"},
    {"branchID":"71","branchName":"Nigeria Institute of Journalism (NIJ)","branchPhone":"","branchMail":"","branchSec":"Nigeria Institute of Journalism (NIJ)","usrahTime":"2014-12-06 00:00:00","usrahVen":"Nigeria Institute of Journalism (NIJ)","usrahDay":"Tuesday","branchType":"Higher Institution Branch","dateAdded":"0000-00-00","modified":"2014-11-30 22:03:19"},
    {"branchID":"72","branchName":"Secondary School","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"School","usrahDay":"Wednesday","branchType":"Secondary School Branch","dateAdded":"0000-00-00","modified":"2014-12-20 07:11:11"},
    {"branchID":"73","branchName":"Ebute-Metta","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"2014-12-22 14:09:47","usrahVen":"Wasimi Community Central Mosque","usrahDay":"Sunday","branchType":"Central Branch","dateAdded":"2014-12-22","modified":"2014-12-22 00:10:40"},
    {"branchID":"74","branchName":"Egan","branchPhone":"","branchMail":"","branchSec":"","usrahTime":"0000-00-00 00:00:00","usrahVen":"","usrahDay":"","branchType":"Central Branch","dateAdded":"2022-12-15","modified":"2022-11-30 18:23:33"},
    {"branchID":"75","branchName":"Lagos State University of Education (LASUED) Ijanikin","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2023-12-27","modified":"2023-12-26 21:06:17"},
    {"branchID":"76","branchName":"Lagos State University of Education (LASUED) Epe","branchPhone":"0","branchMail":"0","branchSec":"0","usrahTime":"0000-00-00 00:00:00","usrahVen":"0","usrahDay":"","branchType":"Higher Institution Branch","dateAdded":"2023-12-27","modified":"2023-12-26 21:06:17"}
    ]

    const branches = list.map(item => ({
        branchName: item.branchName,
        branchType: item.branchType
    }));

export default branches
    